<template>
  <el-table :data="data" class="w-100">
    <el-table-column prop="id" label="ID" width="60"></el-table-column>

    <el-table-column prop="start" label="Start" width="130">
      <template slot-scope="scope">
        {{ format(new Date(scope.row.start), 'dd.MM.yyyy') }}
        <div>
          {{ format(new Date(scope.row.start), 'HH:mm') }} -
          {{ format(new Date(scope.row.end), 'HH:mm') }}
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="description" label="Description" min-width="200"></el-table-column>

    <el-table-column prop="project" label="Project" width="160">
      <template slot-scope="scope">{{ scope.row.project.name }}</template>
    </el-table-column>

    <el-table-column prop="tag" label="Tag">
      <template slot-scope="scope">
        <el-tag :type="getTagType(scope.row.tag.id)">{{ scope.row.tag.name }}</el-tag>
      </template>
    </el-table-column>

    <el-table-column prop="hours" label="Hours" width="100" align="center">
      <template slot-scope="scope">{{ scope.row.hours }}</template>
    </el-table-column>

    <el-table-column prop="billed" label="Billed" width="80" align="center">
      <template slot-scope="scope">
        <el-switch v-model="scope.row.billed" @change="doUpdate(scope.row)"></el-switch>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'TableTrackingResults',

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    format,

    doUpdate(item) {
      this.$store
        .dispatch('tracking/UPDATE_TIMETRACKER', item)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Erfolg',
            message: 'Item has been successfully updated.',
            showClose: true
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Error',
            message: 'There was an error during updating item.',
            showClose: true
          });
        });
    },

    getTagType(id) {
      let type = 'info';

      switch (id) {
        case 1:
          type = 'success';
          break;
        case 2:
          type = 'primary';
          break;
        case 3:
          type = 'warning';
          break;
        case 4:
          type = 'danger';
          break;
      }

      return type;
    }
  }
};
</script>
